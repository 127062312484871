.homeContainer{
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.homeTitle{
  width: 75%;
  font-size: 22px;
}