/* navcontact 00000000000 */
*{
  font-family: 'Roboto', sans-serif;
}


i{
  vertical-align:sub;
}

.navdesktop a,button{
  text-transform: uppercase;
}

.xcvty{
  height: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1.5%;
  padding: 0px 10px;
}

.xcvty a{
  
  color: #141e30;
  cursor: pointer;
  font-size: 0.9rem;
}




/* navmobile container */
.navmobile {
    display: flex;
    align-items: center;
    right: 0;
    overflow: hidden;
    /* background-color: #003580; */
    background: #141e30;
    font-family: Arial;
    width: 100%;
    height: 70px;
  }

  

  .navdesktop {
    display: flex;
    align-items: center;
    right: 0;
    overflow: hidden;
    /* background-color: #003580; */
    background: #ff9818;
    font-family: Arial;
    width: 100%;
    height: 62px;
    /* box-shadow: 0px 0px 2px inset yellow; */
  }

  .navdesktop a{
    color: #fff;
    margin: 0px 20px;
    text-decoration: none;
    font-size: 16px;

  }

  .acolumn a{
    margin: 0px;
  }

  /* .navdesktopcontact{
    position: fixed;
  } */

  @media  (min-width: 0px) and (max-width: 863px) {
    .navdesktopcontact {
      display: none;
    }
  }

  @media  (min-width: 0px) and (max-width: 450px) {

    .hjpysg a, .subnav-study a, .subnaVeurope a, .subnav-asia a, .cxksdfi a{
      font-size: 20px;
      
    }
    
    
  }

  @media  (min-width: 864px) and (max-width: 20000px) {
    .navmobile {
      display: none;
    }
  }

  .navlogomobile {
    display: flex;
    background-image: url("./images/manglam.png");
    background-size: contain;
    background-repeat: no-repeat;
    /* background-color: #003580; */
    width: 90%;
    height: 50px;
    margin: 10px 5px;
  }

  .navlogodesktop {
    display: flex;
    background-image: url("./images/manglam.png");
    background-size: contain;
    background-repeat: no-repeat;
    /* background-color: #003580; */
    position: relative;
    min-width: 130px;
    height: 50px;
    margin: 10px 5px;
    /* background-color:firebrick; */
    background-color:black;
    border-radius: 5px;
    -webkit-filter: drop-shadow(10px 5px 2px #555);
    filter: drop-shadow(5px 5px 2px #555);
  }

  .navlogodesktop:hover{
    background-color:firebrick;
  }
  
  /* Links inside the navmobile */
  .navmobile a {
    float: left;
    font-size: 20px;
    color: white;
    padding: 14px 16px;
    text-decoration: none;
  }
  
  /* The adropdown container */
  .adropdown {
    float: left;
    overflow: hidden;
  }
  
  /* adropdown button */
  .adropdown .adropbtn {
    font-size: 16px;
    border: none;
    outline: none;
    color: #fff;
    padding: 14px 5px;
    background-color: inherit;
    font: inherit; /* Important for vertical align on mobile phones */
    margin: 0; /* Important for vertical align on mobile phones */
  }

  .studyasia{
    display: none;
  }

   

  .btnasia:hover  .studyasia {

    display: block;
  }

  

  
  
  /* adropdown content (hidden by default) */
  .adropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    width: 70%;
    margin-left: 20%;
    left: 0;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 999;
  }

  .studybtns{
    padding: 5px 10px;
    margin-right: 20px;
  }
  
  /* Mega Menu aheadertop, if needed */
  .adropdown-content .aheader {
    background: linear-gradient(to left, #243b55, #141e30);
    padding: 16px;
    color: white;
  }

  /* #studyeurope{
    display: none;
  } */

  /* #studyasia{
    display: none;
  } */


  /* #studyasia{
    display: inline-block;
  } */
  
  
  /* Show the adropdown menu on hover */
  .adropdown:hover .adropdown-content {
    display: block;
  }

 

  
  /* Create three equal acolumns that floats next to each other */
  .acolumn {
    float: left;
    width: 33.33%;
    padding: 10px;
    /* background-color: #ccc; */
    height: 260px;
  }

  .acolum {
    float: left;
    width: 49%;
    padding: 10px;
    /* background-color: #ccc; */
    
  }

  


  
  /* Style links inside the acolumns */
  .acolumn a {
    float: none;
    color: rgb(75, 75, 75);
    padding: 16px 5px;
    text-decoration: none;
    display: block;
    text-align: left;
    font-size: 14px;
    cursor: pointer;
  }
  .acolumn img {
    width: 20px;
  }
  
  /* Add a background color on hover */
  .acolumn a:hover {
    background-color: #ff9818;
    border-radius: 5px;
    
  }
  
  /* Clear floats after the acolumns */
  .arowbox1:after {
    content: "";
    display: table;
    clear: both;
  }

  .arow{
    display: flex;
  }

  .rowbox1{
    width: 50%;
    border-right: 2px solid #ccc
  }
  .rowbox2{
    width: 50%;
  }

  .cgtpn{
    position:absolute;
    right: 10px;
  }

  /* //000000000000000000000 */

  /* The navigation menu */
/* .navbar {
  overflow: hidden;
  background-color: #333;
}

/* Navigation links */
.navbar a {
  float: left;
  font-size: 16px;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

/* The subnavigation menu */
.subnav {
  float: left;
  overflow: hidden;
}

/* Subnav button */
.subnav .subnavbtn {
  font-size: 22px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin-right: 10px;
}

/* Add a red background color to navigation links on hover */
/* .subnav:hover .subnavbtn {
  background-color: #2061da;
} */





  /* Style the subnav content - positioned absolute */
.subnav-content {
  position: absolute;

  top: 60px;
  right: 0;
  /* background-color: #141e30; */
  width: 60%;
  height: 100%;
  z-index: 20;
  margin-top: 5px;
  /* box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.4); */
}

.subnavbtn .subnav-content {
  display: block;
}


/* Style the subnav links */
.subnav-content a {
  float: left;
  color: white;
  text-decoration: none;
  font-size: 17px;
}

/* Add a grey background color on hover */
.subnav-content a:hover {
  background-color: #eee;
  color: black;
}

/* When you move the mouse over the subnav container, open the subnav content */
.subnav:hover .subnav-content {
  display: block;
}

/* hover 2nd box image gallery */

.scroll-container {
  background-color: white;
  overflow: auto;
  white-space: nowrap;
}

.scroll-container-text{
  display: flex;
  justify-content: space-between;
  padding: 10px;

}

.scroll-container img {
  padding: 10px;
  height: 150px;
}

.gksqjxi{
  width: 100%;
}



.hjpysg a{
  background-color: #141e30;
  
  width: 100%;
  height: 50px;
}

.subnav-study a{
  background-color: #141e30;
  width: 100%;
  height: 50px;
}

.subnaVeurope a{
  background-color: #141e30;
  width: 100%;
  height: 60px;

}

.subnav-asia a{
  background-color: #141e30;
  width: 100%;
  height: 60px;
  

}

.cxksdfi a{
  background-color: #141e30;
  width: 100%;
  height: 60px;
  
}


