



body{
	font-family: 'lato';
	color: #111;
	position: relative;
	overflow-x: hidden;
}

.vertical-align{
	position: relative;
	top: 46%;
	transform: translateY(-50%);
}

strong{
	font-weight: 900;
}

p{
	line-height: 24px;
	font-weight: 300px;


}

a,a:focus{
   color: #873fb5
}

a:hover{
	color: #41135f;
	text-decoration: none;
}

section{
	padding: 50px 0;
}
 

 hr.icon:after{
  content: "\f123";
  color:#873fb5;
  font-family: 'FontAwesome';
  font-size: 1.5em;
  background-color: white;
  top: -0.7em;
  padding: -.50em;
 }

 .section-heading{
 	color:#873fb5;
 }

 .section-heading h3{
 	font-weight: 300;
 	Letter-spacing: 3px;
 }

 .section-heading hr{
 	border-color: #873fb5;
 	margin-top: 30px;
 	margin-bottom: 40px;
 }


 #home{

/* background: url("../images/aboutback.jpg") fixed; */
background-repeat: no-repeat;
background-position: center;
background-size: cover;
background-color: #141e30;
color: white;
height: 600px;

 }

 .aboutlogo{
	margin: 20px 0px;
	background-image: url("../images/manglam.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	border-radius: 10px;
	
	height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
 }

.logo{
	font-size: 5vw;

	font-weight: 100;
	border-top: 1px solid white;
	border-bottom: 1px solid white;
	display: inline-block;
	padding: 0 20px 15px 20px;

}

@media (max-width: 400px) {
    
  .logo{
	font-size: 10vw;
  }

  #home{
	height: 318px;
  }

  .aboutlogo{
	height: 56px;
  }


}






#home h3{

font-weight: 300;
margin-top: 66px;

}	

#home .learn-more{

color: white;
font-size: 30px;
position: absolute;


}

.learn-more .fa{
position: absolute;

}

.learn-more .second-arrow{

  top: 9px;
}

.learn-more .third-arrow{

  top: 18px;
}

#about{

background: url("http://www.goodwp.com/images/201103/goodwp.com_16835.jpg")fixed;
background-repeat: no-repeat;
background-position: center;
background-size: cover;
color: white;
height: 500px;

}

#about h3{
	font-weight: 300;
	letter-spacing: 3px;
}

#about h4{
	font-weight: 300;
	padding: 0 20%;
	
}

.service-item{

margin-bottom: 40px;

}

.service-item i{

 font-size: 48px;
 color: #8BFF8B;
 float: left;
 border-right: 1px solid #8BFF8B;
 padding-right: 15px;

}

.service-title{
margin-top: -25px;
margin-left: 15px;
display: inline-block;

}

.service-title h3{

	color: #111;
	font-weight: 900;
}

.service-title h4{

	font-weight: 300;
	color: #873fb5;
	margin-top: -5px;
}

#skills{

background: url("https://awesomewallpaper.files.wordpress.com/2013/03/img7.jpg") fixed;
background-repeat: no-repeat;
background-position: center;
background-size: cover;
color: white;



}

#skills h1{

	font-size: 48px;

}
#skills h3{

	font-weight: 300;
	margin-top:0; 
	
}

#skills p{

	margin: 40px 0;
	font-weight: 300;
}

#skills .button:last-child{
	margin-left: 3px;
}

.button{

color: white;
font-size: 16px;
}

.progress{

	height: 27px;
	margin-bottom: 30px;
	opacity: .78;
	background-color: white;
	

}

.progress-bars h5{
	font-weight: 900;
	text-transform: uppercase;
}

#portfolio .col-md-4{

padding: 0;

}

.portfolio-thumb,.portfolio-overlay{

	height: 270px;
	max-width: 390px;
}

#portfolio .portfolio-thumb img{
	width: 100%;
	height: 100%;
}


#portfolio .portfolio-thumb{
	overflow: hidden;
	padding: 0;
	margin: 0 auto;
}

#portfolio .portfolio-thumb .portfolio-overlay{

opacity: 0;
height: 100%;
width: 100%;
top: 0;
bottom: 0;
left: 0;
right: 0;
text-align: center;
position: absolute;
background: rgba(135,63,181,.5);
padding: 20px 10px 20px 20px;
margin-left: auto;
margin-right: auto;
transition: all 0.2s ease-in-out;
-webkit-transition: all 0.2s ease-in-out;



}

/* #portfolio .portfolio-thumb:hover  .portfolio-overlay{

opacity: 1;
cursor: pointer;

} */

#portfolio .portfolio-thumb .portfolio-overlay h2{

color:white;
font-weight: 300;
border-top: 1px solid white;
border-bottom: 1px solid white;
display: inline-block;
padding: 5px 10px 10px 10px;

}

#portfolio .portfolio-thumb .portfolio-overlay a{

color: white;
font-size: 24px;
position: absolute;
right: 20px;
bottom: 20px;

}

.stat{

	height: 230px;
	color: white


}

.stat i{
	font-size: 48px;
	

}

.stat h4{
	font-weight: 300;
	
}


.codes{
	background: url("http://i.imgur.com/nyljaMX.png");
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	background-size: cover;
}

.projects{
	background: url("https://havneethuda.files.wordpress.com/2015/03/blue-hour-traffic.jpg");
	background-repeat: no-repeat;
	background-position: center;
	-webkit-background-size: cover;
	background-size: cover;
}

.clients{
	background: url("https://silanis-iprospectcom.netdna-ssl.com/blog/wp-content/uploads/2014/11/Fintech.jpg");
	background-repeat: no-repeat;
	background-position: center;
	-webkit-background-size: cover;
	background-size: cover;
}

.feedback{
	background: url("http://blog.evercoach.com/wp-content/uploads/2015/12/shutterstock_229841482.jpg");
	background-repeat: no-repeat;
	background-position: center;
	-webkit-background-size: cover;
	background-size: cover;
}

#quotes{

background: url("http://i.imgur.com/TKkQ3sZ.jpg");
background-repeat: no-repeat;
background-position: center;
background-size: cover;
color: white;
height: 500px;
position: relative;
margin: 0 35px 0 35px;

}

.form-control{

height: 45px;
padding: 15px;
font-size: 16px;
color: #8c8c8c; 
background-color: #f5f5f5;
border: 1px solid #d1d1d1;
box-shadow: none;
font-weight: 300;
}

.form-control:focus{
	box-shadow: none;
	color: #793a93;
	border-color:  #793a93;
}

.form-control::-moz-placeholder{
	color: #8c8c8c;
}

.form-control::-ms-input-placeholder{
	color: #8c8c8c;
}

.form-control::-webkit-input-placeholder{
	color: #8c8c8c;
}


.form-control:focus::-moz-placeholder{
	color: #793a93;
}

.form-control:focus::-ms-input-placeholder{
	color: #793a93;
}

.form-control:focus::-webkit-input-placeholder{
	color: #793a93;
}


.copyrights{
	background-color: rgba(0,0,0,0.5);
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	text-align: center;
	font-size: 12px;
	padding: 5px 0;

}

@media (min-width: 970px) and (max-height: 1170px){

.service-item i{
	float: none;
	border: none;
	display: block;

}

.service-item{
	text-align: center;
}

.service-title{
	margin-top: 0;
	margin-left: 0
}

}

@media(max-width: 750px){

.progress-bars{
	margin-top: 30px;
}

.google-map{
	margin-top: 20px;
}

}

/* 000000000000000000000000000000000000000000000000000000000000000000000 */
body {
	font-family: Arial, Helvetica, sans-serif;
	margin: 0;
  }
  
  html {
	box-sizing: border-box;
  }
  
  *, *:before, *:after {
	box-sizing: inherit;
  }
  
  .abcolumn {
	float: left;
	width: 25%;
	margin-bottom: 16px;
	padding: 0 8px;
  }
  
  .card {
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	margin: 8px;
  }
  
  .about-section {
	padding: 50px;
	text-align: center;
	color: black;
  }
  
  .container {
	padding: 25px 16px;
  }

  .ghsdf{
	margin-top: 40px;
  }
  
  .container::after, .row::after {
	content: "";
	clear: both;
	display: table;
  }
  
  .title {
	color: grey;
  }
  
  .button {
	border: none;
	outline: 0;
	display: inline-block;
	padding: 8px;
	color: white;
	background-color: #000;
	text-align: center;
	cursor: pointer;
  }
  
  .button:hover {
	background-color: #555;
  }
  
  @media screen and (max-width: 650px) {
	.abcolumn {
	  width: 50%;
	  display: block;
	}
  }

  @media screen and (max-width: 400px) {
	.abcolumn {
	  width: 100%;
	  display: block;
	}
  }
  

  .row{
	display: flex;
	justify-content: center;
  }







  /* 0000000000000000000000000000000000000000000000000000000000000000000000000000000 */


  /* Slideshow container */
.slideshow-container {
	position: relative;
	background: #f1f1f1f1;
  }
  
  /* Slides */
  .mySlides {
	padding: 50px;
	text-align: center;
  }
  
  /* Next & previous buttons */
  .prev, .next {
	cursor: pointer;
	position: absolute;
	top: 50%;
	width: auto;
	margin-top: -30px;
	padding: 16px;
	color: #888;
	font-weight: bold;
	font-size: 20px;
	border-radius: 0 3px 3px 0;
	user-select: none;
  }
  
  /* Position the "next button" to the right */
  .next {
	position: absolute;
	right: 0;
	border-radius: 3px 0 0 3px;
  }
  
  /* On hover, add a black background color with a little bit see-through */
  .prev:hover, .next:hover {
	background-color: rgba(0,0,0,0.8);
	color: white;
  }
  
  /* The dot/bullet/indicator container */
  .dot-container {
	text-align: center;
	padding: 20px;
	background: #ddd;
  }
  
  /* The dots/bullets/indicators */
  .dot {
	cursor: pointer;
	height: 15px;
	width: 15px;
	margin: 0 2px;
	background-color: #bbb;
	border-radius: 50%;
	display: inline-block;
	transition: background-color 0.6s ease;
  }
  
  /* Add a background color to the active dot/circle */
  .active, .dot:hover {
	background-color: #717171;
  }
  
  /* Add an italic font style to all quotes */
  q {
	font-style: italic;
	color: black;
}
  
  /* Add a blue color to the author */
  .author {
	color: blue;
	font-size: 16px;
}

.authordiv{
	display: flex;
	justify-content: center;
	align-items: center;
}

.profile{
	background-size: cover;
	background-position: center;
	height: 100px;
	width: 100px;
	border-radius: 50%;
	margin: 15px 0px;

}

.author1{
	background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLs8R1pvb1aUlkltBCpZRao9kJwMcEbPARZg&usqp=CAU");
}

.author2{
	background-image: url("https://media.licdn.com/dms/image/C4E03AQHqjjvwL8ahGA/profile-displayphoto-shrink_800_800/0/1517721687804?e=2147483647&v=beta&t=wlyvneRehdTmdU2sjONQAaHexJMo0XhMnSNyeiwIQv4");
}

.author3{
	background-image: url("https://nbcsports.brightspotcdn.com/dims4/default/9a6f086/2147483647/strip/true/crop/5578x3138+0+133/resize/1440x810!/quality/90/?url=https%3A%2F%2Fnbc-sports-production-nbc-sports.s3.us-east-1.amazonaws.com%2Fbrightspot%2Fe3%2Fd7%2F9a427da34794b73f12ea474fe937%2Fhttps-delivery-gettyimages.com%2Fdownloads%2F1612059413");
}

.author4{
	background-image: url("https://m.media-amazon.com/images/I/61BjJOnt7yL._AC_UY1100_.jpg");
}

.author5{
	background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQT4ADgntChdGnCPAaEgtRNNCiPxxprBO46D__5GEjj6ix_igKjbe5w2Iz9FW4s7z_1h9g&usqp=CAU");
}

.author6{
	background-image: url("https://a10.gaanacdn.com/gn_img/artists/a7LWBkzbzX/a7LWBkzbzX/size_xl_1516780880.webp");
}




 /* 000000000000000000000000000000000000000000000000000000000000000000000000000000000 */

 
.swiper {
	width: 100%;
	height: 100%;
  }
  
  .swiper-slide {
	text-align: center;
	font-size: 18px;
	background: #fff;
  
	/* Center slide text vertically */
	display: flex;
	justify-content: center;
	align-items: center;
  }
  
  .swiper-slide img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
  }



 /* 00000000000000000000000000000000000000000000000000000000 */