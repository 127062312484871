.fp {
  width: 100%;
  max-width: 1024px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  overflow-x: scroll;
}



.fpscroll{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 1000%;
  padding: 0px 3px;
  cursor: pointer;
}

.fpItem{
  flex: 1;
  gap: 10px;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.fpImg{
  width: 100%;
  height: 250px;
  width: 240px;
  object-fit: cover;
}

.fpName{
  color: #333;
  font-weight: bold;
}

.fpCity{
  font-weight: 300;
}

.fpPrice{
  font-weight: 500;
}

.fpRating>button{
  background-color: #003580;
  color: white;
  border: none;
  padding: 3px;
  margin-right: 10px;
  font-weight: bold;
}

.fpRating>span{
  font-size: 14px;
}