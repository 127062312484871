*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'AR One Sans', sans-serif;
}

.djklds{
  height: auto;
  width: 100%;
  overflow-x: scroll;
  padding: 10px 5px;
  /* background-color: #fafafa; */
  text-align: center;
  cursor: pointer;
}

.djkld{
  height: auto;
  width: 100%;
  overflow-x: scroll;
  padding: 10px 5px;
  text-align: center;
}







.eSYVyh {
    position: fixed;
    z-index: 50;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: none;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  /* sc-component-id: component__Wrapper-sc-1jrr6iz-1 */
  .hscwIb {
    background-color: #fff;
    position: relative;
    text-align: center;
    padding: 20px;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 400px;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
      0px 5px 8px 0px rgba(0, 0, 0, 0.14),
      0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  }

  /* sc-component-id: component__CloseBtn-sc-1jrr6iz-4 */
  .cLrLNh {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }
  /* sc-component-id: styled__Section-sc-1ji51nh-0 */
  .dRCKEU {
    /* height: 480px; */
    padding: 50px 15px;
  }
  /* sc-component-id: styled__Container-sc-1ji51nh-1 */
  .bQcjpC {
    max-width: 888px;
    margin: 0 auto;
  }
  @media (max-width: 723px) {
    .bQcjpC {
      max-width: 500px;
    }
  }
  /* sc-component-id: styled__PulseBtn-sc-1ji51nh-3 */
  .caLgOF {
    padding: 30px;
    color: #fff;
    background-color: #ff9818;
    font-size: 16px;
    border: none;
    /* border-radius: 40px; */
    cursor: pointer;
    -webkit-animation: pulse 2s infinite;
    animation: pulse 2s infinite;
    width: 285px;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property:all;
    transition-property:all;
  }
  .caLgOF:focus {
    outline: none;
  }
  .caLgOF:hover {
    background-color: #ff8e00;
  }
  @-webkit-keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 152, 24, 0.4);
    }
    70% {
      box-shadow: 0 0 0 15px rgba(255, 152, 24, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(255, 152, 24, 0);
    }
  }
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 152, 24, 0.4);
    }
    70% {
      box-shadow: 0 0 0 15px rgba(255, 152, 24, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(255, 152, 24, 0);
    }
  }
  /* sc-component-id: cover__Wrapper-sc-7fa62t-0 */
  .ehlOuW {
    height: 650px;
    padding-top: 150px;
    background: url(./images/cover.png) 80% 50px / contain no-repeat,
      linear-gradient(to right, #64acb7, #68c6e0);
  }

.ghjjk{
  width: 100vw;
  height: 455px;
  background: linear-gradient(to right, #64acb7, #68c6e0);
  
  display: flex;
  justify-content: center;
  align-items: center;
  
}

@media  (min-width: 0px) and (max-width: 449px) {
  .ehlOuW {
    display: none;
  }
  .dess{
    display: none;
  }

}




@media  (min-width: 450px) and (max-width: 20000px) {
  .ghjjk {
    display: none;
  }

  .mobb{
    display: none;
  }
}



 

   @media (max-width: 939px) {
    .ehlOuW {
      background: url(./images/cover.png) 150% 50px / contain
          no-repeat,
        linear-gradient(to right, #64acb7, #68c6e0);
    }
  } 
  @media (max-width: 723px) {
    .ehlOuW {
      background: linear-gradient(to right, #64acb7, #68c6e0);
      height: auto;
      padding: 50px 0;
    }
  }
  /* sc-component-id: cover__Container-sc-7fa62t-1 */
  .ryIus {
    max-width: 888px;
    margin: 0 auto;
  }
  @media (max-width: 723px) {
    .ryIus {
      max-width: 500px;
    }
  }
  @media (max-width: 939px) {
    .ryIus {
      padding: 0 25px;
    }
  }
  @media (max-width: 723px) {
    .ryIus {
      text-align: center;
    }
  }
  /* sc-component-id: cover__Title-sc-7fa62t-2-h1 */
  .jBPgjx {
    color: #fff;
  }
  @media (min-width: 723px) {
    .jBPgjx {
      text-align: left;
    }
  }
  /* sc-component-id: cover__Input-sc-7fa62t-3 */
  .cucclX {
    padding: 10px 10px;
    /* border-radius: 20px; */
    border: 1px solid #ff9818;
    outline: none;
    text-align: center;
    font-size: 14px;
    width: 285px;
    margin-bottom: 5px;
  }
  /* sc-component-id: continents__Section-mu4rdf-0 */
  .kELZqj {
    height: auto;
    padding: 20px 15px;
    text-align: center;
  }

  .kELZq {
    height: auto;
    padding: 20px 15px;
    /* background-color: #cac6c6; */
    text-align: center;
  }
  /* sc-component-id: continents__Title-mu4rdf-1 */
  .kYBuig {
    font-size: 50px;
    text-align: center;
    margin-top: 0;
    font-size: 50px;
  }
  /* sc-component-id: continents__Wrapper-mu4rdf-2 */
  .dgFnke {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  /* sc-component-id: continents__Continent-mu4rdf-3 */
  .jeUIoc {
    display: block;
    cursor: pointer;
  }
  .jeUIoc:hover p {
    font-weight: bold;
  }
  .jeUIoc:hover div {
    -webkit-filter: drop-shadow(0 5px 2px #555);
    filter: drop-shadow(0 5px 2px #555);
  }
  /* sc-component-id: continents__EuropeImg-mu4rdf-5 */
  .cpzfEQ {
    margin: 0 auto;
    background-image: url(./images/continents.png);
    background-repeat: no-repeat;
    background-size: auto;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    width: 250px;
    height: 250px;
    background-position: 0px 0px;
  }
  /* sc-component-id: continents__AsiaImg-mu4rdf-6 */
  .cgDwQz {
    margin: 0 auto;
    background-image: url(./images/continents.png);
    background-repeat: no-repeat;
    background-size: auto;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    width: 300px;
    height: 250px;
    margin-left: 20px;
    background-position: -260px 10px;
  }
  /* sc-component-id: continents__AustraliaImg-mu4rdf-7 */
  .fjUZAo {
    margin: 0 auto;
    background-image: url(./images/continents.png);
    background-repeat: no-repeat;
    background-size: auto;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    width: 280px;
    height: 290px;
    background-position: -560px 10px;
  }
  /* sc-component-id: biometrics__Section-sc-1qjc3xo-0 */
  .iwBKEP {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    background: linear-gradient(to left, #8f94fb, #93cede);
  }

  .iwBKE {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    background: linear-gradient(to left, #8f94fb, #93cede);
  }






  @media (max-width: 723px) {
    .iwBKEP {
      text-align: center;
      color: white;
      height: auto;
    }
  }
  /* sc-component-id: biometrics__TextSection-sc-1qjc3xo-1 */
  .eFpeKG {
    background-color: #fff;
    height: 100%;
    position: relative;
    z-index: 5;
    padding: 50px 0;
  }
  @media (max-width: 723px) {
    .eFpeKG {
      width: 100%;
      background: linear-gradient(to left, #8f94fb, #93cede);
    }
  }
  /* sc-component-id: biometrics__WaveStyled-sc-1qjc3xo-2 */
  .eoBsPU {
    position: absolute;
    right: 95%;
    top: 0;
    width: 230px;
    z-index: 5;
  }
  @media (max-width: 723px) {
    .eoBsPU {
      display: none;
    }
  }
  /* sc-component-id: biometrics__TextWrapper-sc-1qjc3xo-3 */
  .dkhatj {
    position: relative;
    z-index: 10;
  }
  .dkhatj ul {
    margin-bottom: 30px;
  }
  /* sc-component-id: biometrics__Title-sc-1qjc3xo-4 */
  .dynRLe {
    font-size: 40px;
    max-width: 400px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  @media (max-width: 723px) {
    .dynRLe {
      margin: 0 auto;
    }
  }
  /* sc-component-id: biometrics__FingerPrintImg-sc-1qjc3xo-5 */
  .ebVVUT {
    display: block;
    position: absolute;
    right: 125%;
    top: 75px;
  }
  /* sc-component-id: biometrics__List-sc-1qjc3xo-6 */
  @media (max-width: 723px) {
    .iOzoBu {
      padding: 0;
      list-style: none;
    }
  }
  /* sc-component-id: biometrics__ListItem-sc-1qjc3xo-7 */
  .bSoyTd {
    margin-bottom: 10px;
  }
  /* sc-component-id: advantages__Title-sc-1rvjoc-0 */
  .iTdKte {
    font-size: 50px;
    text-align: center;
    margin-top: 0;
    font-size: 40px;
  }
  /* sc-component-id: advantages__SubTitle-sc-1rvjoc-1 */
  .kussSZ {
    text-align: center;
    margin-bottom: 20px;
  }
  /* sc-component-id: advantages__List-sc-1rvjoc-2 */
  .kAptZk {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-around;
    -webkit-justify-content: space-around;
    -ms-flex-pack: space-around;
    justify-content: space-around;
    list-style: none;
    padding: 0;
    margin: 40px 0;
  }
  .kAptZk li {
    width: 200px;
    text-align: center;
  }
  .kAptZk svg {
    display: block;
    width: 75px;
    height: 75px;
    margin: 0 auto;
    color: #003580;
  }
  /* sc-component-id: advantages__Text-sc-1rvjoc-3 */
  .kTMrvA {
    text-align: center;
    max-width: 500px;
    margin: 0 auto;
  }
  /* sc-component-id: tours__Section-bhr5fb-0 */
  .hxJnWr {
    height: 500px;
    padding: 50px 15px;
    background-image: url(./images/switz.jpg);
    background-size: cover;
    background-position: center;
    position: relative;
    color: #fff;
  }
  /* sc-component-id: tours__Overlay-bhr5fb-1 */
  .jzexuY {
    background-color: #000;
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  /* sc-component-id: tours__Title-bhr5fb-2 */
  .bUWTBM {
    font-size: 50px;
    text-align: center;
    position: absolute;
    z-index: 5;
    top: 130px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  /* sc-component-id: tours__Button-bhr5fb-3 */
  .kYYayU {
    padding: 30px;
    color: #fff;
    background-color: #ff9818;
    font-size: 20px;
    border: none;
    border-radius: 40px;
    cursor: pointer;
    -webkit-animation: pulse 2s infinite;
    animation: pulse 2s infinite;
    width: 285px;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: box-shadow;
    transition-property: box-shadow;
    position: absolute;
    z-index: 5;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    top: 300px;
  }
  .kYYayU:focus {
    outline: none;
  }
  .kYYayU:hover {
    background-color: #ff8e00;
  }
  @-webkit-keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 152, 24, 0.4);
    }
    70% {
      box-shadow: 0 0 0 15px rgba(255, 152, 24, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(255, 152, 24, 0);
    }
  }
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 152, 24, 0.4);
    }
    70% {
      box-shadow: 0 0 0 15px rgba(255, 152, 24, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(255, 152, 24, 0);
    }
  }
  /* sc-component-id: services__Title-ddmf7w-0 */
  .eXcXBg {
    font-size: 50px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 60px;
  }
  /* sc-component-id: services__List-ddmf7w-1 */
  .grYKsD {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    -webkit-box-pack: space-around;
    -webkit-justify-content: space-around;
    -ms-flex-pack: space-around;
    justify-content: space-around;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  /* sc-component-id: services__Item-ddmf7w-2 */
  .hTXiu {
    max-width: 150px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    cursor: pointer;
  }
  .hTXiu:hover {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  /* sc-component-id: services__Img-ddmf7w-3 */
  .fwtetB {
    margin: 0 auto;
    width: 150px;
    height: 150px;
    border-radius: 100px;
    background-size: auto;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-image: url(./images/services.png);
  }
  .gdDHuq {
    margin: 0 auto;
    width: 150px;
    height: 150px;
    border-radius: 100px;
    background-size: auto;
    background-repeat: no-repeat;
    background-position: -155px 0;
    background-image: url(./images/services.png);
  }
  .cGheSV {
    margin: 0 auto;
    width: 150px;
    height: 150px;
    border-radius: 100px;
    background-size: auto;
    background-repeat: no-repeat;
    background-position: -305px 0;
    background-image: url(./images/services.png);
  }
  .lcrnjC {
    margin: 0 auto;
    width: 150px;
    height: 150px;
    border-radius: 100px;
    background-size: contain;
    background-image: url(./images/abroadstudy.png);
    background-repeat: no-repeat;
    background-position: center;
  }
  .kQpRaS {
    margin: 0 auto;
    width: 150px;
    height: 150px;
    border-radius: 100px;
    background-size: auto;
    background-repeat: no-repeat;
    background-position: -605px 0;
    background-image: url(./images/services.png);
  }
  /* sc-component-id: shared__Item-sc-18ldjbv-1 */
  .cSzncO {
    line-height: 48px;
    text-transform: uppercase;
    padding: 0 10px;
    text-align: center;
  }
  .cSzncO a {
    color: #fff;
    -webkit-text-decoration: none;
    text-decoration: none;
  }
  /* sc-component-id: mobile__ListMobile-sc-1ogul98-0 */
  .ZnbHc {
    width: 100%;
    list-style: none;
    display: flex;
    
    justify-content: space-around;
   
  }
  @media (min-width: 939px) {
    .ZnbHc {
      display: none;
    }
  }
  @media (max-width: 939px) {
    .ZnbHc {
      width: 564px;
    }
  }
  @media (max-width: 723px) {
    .ZnbHc {
      width: 100%;
    }
  }
  @media (max-width: 400px) {
    .ZnbHc {
      width: 185px;
    }
  }
  /* sc-component-id: mobile__MenuBtn-sc-1ogul98-1 */
  .iesvDp {
    line-height: 48px;
    font-size: 20px;
  }
  /* sc-component-id: mobile__HiddenMenu-sc-1ogul98-2 */
  .hQkjSA {
    position: fixed;
    width: 200px;
    top: 100;
    right: 0;
    /* height: 100%; */
    -webkit-transition: 0.5s;
    transition: 0.5s;
    padding-top: 10px;
    background-color: #ff9818;
    /* background-color: rgba(0, 0, 0, 0.50); */
    /* background-color: #ff9818; */
    box-shadow: -5px 0 5px rgba(69, 90, 100, 0.5);
    z-index: 15;
    display: none;
       
  }

  .iesvDp:hover .hQkjSA {
    
    display: block;   
  }

  .hQkjSA a {
    color: #fff;
    -webkit-text-decoration: none;
    text-decoration: none;
  }
  /* sc-component-id: mobile__HiddenItem-sc-1ogul98-3 */
  .dyhFYf {
    padding-left: 10px;
  }
  /* sc-component-id: desktop__ListDesktop-sc-1jp4ow6-0 */
  .gknSAG {
    list-style: none;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    width: 100%;
    background-color: yellow;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0;
    margin: 0;
  }
  @media (max-width: 939px) {
    .gknSAG {
      display: none;
    }
  }
  /* sc-component-id: menu__Wrapper-lwc5ib-0 */
  .cgbeMz {
   
    /* background-color: #ff9818; */
    background-color: black;
    width: 100%;
    height: auto;
    color: #fff;
  }
  /* sc-component-id: info-bar__Wrapper-eka3r5-0 */
  .cCJBhi {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  /* sc-component-id: info-bar__Address-eka3r5-1 */
  .flqqxI {
    font-size: 15px;
  }
  .flqqxI p {
    margin: 0;
    line-height: 24px;
  }
  @media (min-width: 1229px) {
    .flqqxI {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
    }
    .flqqxI p {
      margin-left: 30px;
      line-height: 48px;
    }
  }
  @media (max-width: 939px) {
    .flqqxI {
      display: none;
    }
    .iGaRmN{
      display: none;
    }
  }
  /* sc-component-id: info-bar__WorkingHours-eka3r5-2 */
  .bsHfqV {
    color: #1e8edd;
  }
  /* sc-component-id: info-bar__Contacts-eka3r5-3 */
  .iGaRmN {
    font-size: 25px;
    height: 48px;
  }
  .iGaRmN a {
    color: #000;
    -webkit-text-decoration: none;
    text-decoration: none;
    line-height: 48px;
  }
  .iGaRmN svg {
    font-size: 28px;
    vertical-align: text-bottom;
    color: #1e8edd;
  }
  .iGaRmN b {
    color: #1e8edd;
  }
  @media (max-width: 400px) {
    .iGaRmN {
      font-size: 18px;
    }
    .iGaRmN svg {
      font-size: 20px;
    }
  }
  /* sc-component-id: info-bar__Callback-eka3r5-4 */
  .iBOrEB {
    height: 48px;
    font-size: 17px;
    color: #1e8edd;
    cursor: pointer;
  }
  .iBOrEB a {
    line-height: 48px;
  }
  .iBOrEB svg {
    font-size: 22px;
    vertical-align: text-bottom;
  }
  .iBOrEB span {
    border-bottom: 1px solid;
  }
  @media (max-width: 723px) {
    .iBOrEB {
      display: none;
    }
  }
  /* sc-component-id: app-bar__Wrapper-sc-11jfg09-0 */
  .ixHDRl {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  /* sc-component-id: header__Wrapper-sc-1eqpxt8-0 */
  .ifBNsH {
    background-color: #fff;
    overflow-x: hidden;
  }
  /* sc-component-id: header__Container-sc-1eqpxt8-1 */
  .kQjffJ {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    
    /* margin: 0 auto; */
  }
  @media (max-width: 1229px) {
    .kQjffJ {
      width: 940px;
    }
  }
  @media (max-width: 939px) {
    .kQjffJ {
      width: 724px;
    }
  }
  @media (max-width: 723px) {
    .kQjffJ {
      width: 480px;
    }
  }
  /* sc-component-id: header__Logo-sc-1eqpxt8-2 */
  .hvERpx {
    display: block;
    background-size: contain;
    background-color: black;
    background-image: url(./images/mainLogo.svg);
    background-repeat: no-repeat;
    background-position: center;
    width: 170px;
    height: 100%;
    cursor: pointer;
  }
  /* sc-component-id: footer__Section-sc-17nhkos-0 */
  
  /* sc-component-id: footer__Container-sc-17nhkos-1 */
 
  /* sc-component-id: footer__Contacts-sc-17nhkos-2 */
  .cyVaEW {
   
    color: white;
    font-size: 12px;
  }
  
  /* sc-component-id: footer__SocialBtns-sc-17nhkos-3 */
  .gFHYQN {background-color: #8f94fb;
    margin:20px
  }
 
  /* sc-component-id: footer__Map-sc-17nhkos-4 */
 @media (max-width: 320px) {
    .XpJTU {
      width: 100%;
    }
  }
  /* sc-component-id: footer__ContactLink-sc-17nhkos-5 */
  .jCBXRa {
    color: #fff;
    -webkit-text-decoration: none;
    text-decoration: none;
  }
  /* sc-component-id: sc-global-4288043874 */
  html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
  }
  body {
    margin: 0;
  }
  main {
    display: block;
  }
  /* h1 {
    font-size: 2em;
    margin: 0.67em 0;
  } */
  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }
  pre {
    font-family: monospace, monospace;
    font-size: 1em;
  }
  a {
    background-color: transparent;
  }
  abbr[title] {
    border-bottom: none;
    -webkit-text-decoration: underline;
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
  }
  b,
  strong {
    font-weight: bolder;
  }
  code,
  kbd,
  samp {
    font-family: monospace, monospace;
    font-size: 1em;
  }
  small {
    font-size: 80%;
  }
  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }
  sub {
    bottom: -0.25em;
  }
  sup {
    top: -0.5em;
  }
  img {
    border-style: none;
  }
  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
    border-radius: 10px;
  }
  button,
  input {
    overflow: visible;
  }
  button,
  select {
    text-transform: none;
  }
  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    appearance: button;
  }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
  }
  fieldset {
    padding: 0.35em 0.75em 0.625em;
  }
  legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal;
  }
  progress {
    vertical-align: baseline;
  }
  textarea {
    overflow: auto;
  }
  [type="checkbox"],
  [type="radio"] {
    box-sizing: border-box;
    padding: 0;
  }
  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }
  [type="search"] {
    appearance: textfield;
    outline-offset: -2px;
  }
  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
  }
  details {
    display: block;
  }
  summary {
    display: list-item;
  }
  template {
    display: none;
  }
  [hidden] {
    display: none;
  }
  * {
    font-family: "PT Sans", sans-serif;
  }
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

/* 000000000 mega menu 000000000000 */
  
  
  /* Responsive layout - makes the three columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .column {
      width: 100%;
      height: auto;
    }
  }


  .cont1{
    height: 600px;
    padding: 10px;
    background-color: #68c6e0;
  }

  .cont1 p{
    background-color: black;
    color: white;
    border-radius: 10px;
    padding: 10px;
  }

  

  .xxg{
    margin-top: 10px;
  }

  
  /* 00000000000000000000000000000000000000000000000 */
  .main{

    height: 450px;
    width: 100%;
    overflow-x:scroll;
  }
  
  .extramain{
    width: 735%;
    height: 450px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #141e30;
  }
  
  .bg-image {
    height: 447px;
    width:323px;
    margin-left: 3px;
    background-size: contain;
    background-repeat: no-repeat;
    border: 5px solid black;
    border-radius: 10px;
  }
  
  /* Images used */
  .img1 
  { background-image: url("./images/study/study1.jpg");
  }
  .img2 { background-image: url("./images/visa/visa1.jpg"); 
  }
  .img3 { background-image: url("./images/study/immigration1.jpg"); 
  }
  .img4 { background-image: url("./images/study//study3.jpg"); 
  }
  .img5 { background-image: url("./images/tour/tour3.jpg");
  }
  .img6 { background-image: url("./images/study/study2.jpg"); 
  }
  .img7 { background-image: url("./images/tour/tour1.jpg"); 
  }
  .img8 { background-image: url("./images/tour/tour4.jpg"); 
  }

  
  /* 000000000000000000000000000000000000000000000000000 */

  .newSwiper {
    width: 800px;
  }
  .newSwiper .swiper-slide {
    height: 300px;
    background: #882525;
    line-height: 300px;
    text-align: center;
    background-image: url("https://images.click.in/classifieds/images/116/17_02_2020_17_42_53_3e1e1167829e31c24ca33aa94235f7f2_ly39i5rue7.jpg");
    background-position: center;
    background-size: contain;
    border: 1px solid black;
    margin-right: 5px;
    border-radius: 10px;
  }
  .newSwiper .swiper-slide:nth-child(2) {
    background: #8acc7d;
    background-image: url("https://i0.wp.com/kpadhne.com/wp-content/uploads/2023/01/Australia-Work-Visa-2023-Work-Permit-Coast-Eligiblity.jpg?fit=940%2C788&ssl=1");
    background-position: center;
    background-size: contain;
    
  }
  .newSwiper .swiper-slide:nth-child(3) {
    background: #b7cc7d;
    background-image: url("https://media.licdn.com/dms/image/D4D12AQGF_K-iAsDtRQ/article-cover_image-shrink_600_2000/0/1692609206411?e=2147483647&v=beta&t=ZqPNWS6lyDJ5_dM2pbd6fjIbpe5c35-oculKY_NdIEY");
    background-position: center;
    background-size: contain;
  }
  .newSwiper .swiper-slide:nth-child(4) {
    background: #fff;
    background-image: url("https://fiverr-res.cloudinary.com/images/t_main1,q_auto,f_auto,q_auto,f_auto/gigs2/131014070/original/c694072bf3de317b949961591a0912cd943e6dbf/provide-uk-tourist-and-immigration-help-services.jpg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .newSwiper .swiper-slide:nth-child(5) {
    background: #7da8cc;
    background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRaxCLLJY14b1biwfvv3BZkutCLDOlAQQ_xBvr0etPjVJfZeeYTWK8Or27Mu7vqc2yXano&usqp=CAU");
    background-position: cover;
    background-size: cover;
  }
  .newSwiper .swiper-slide:nth-child(6) {
    background: #96cc7d;
    background-image: url("https://germanyworkvisa.files.wordpress.com/2017/11/germany-work-visa.jpg");
    background-position: contain;
    background-size: cover;
  }
  .newSwiper .swiper-slide:nth-child(7) {
    background: #cc7dae;
    background-image: url("https://media.licdn.com/dms/image/C5112AQGOuxr0KsYTGw/article-cover_image-shrink_600_2000/0/1571826306518?e=2147483647&v=beta&t=rS7QbXDtQg8X8xq3OCu72VlplwwLg13VFPoRUNYNW0c");
    background-position: left;
    background-size: cover;
  }
  .newSwiper .swiper-slide:nth-child(8) {
    background: #c02183;
    background-image: url("https://images.locanto.me/Poland-Work-permit-multiple-visa-2023/vap_5370619169.jpg");
    background-position: center;
    background-size: contain;
  }
  .newSwiper .swiper-slide:nth-child(9) {
    background: #5f0a3e;
    background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvK_duvBYmiueJ9QmTrpkqDkjkBtESfsybI2BgqZgmEn7ExZTL0qtSFEDoVxeWtb9pTM0&usqp=CAU");
    background-position: center;
    background-size: contain;
  }
  .newSwiper .swiper-slide:nth-child(10) {
    background: transparent;
    border: none;
  }
  .newSwiper .swiper-slide:nth-child(11) {
    background: transparent;
    border: none;
  }

  /* 00000000000000000000000000000000000000000000000000000000000000000 */






  /* 00000000000000000000000000000000000000000000000000000000000000 */