/* @import url('http://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap'); */

        @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');
        @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
        @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');
        @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
        @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');
        @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
        @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap');
        @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@900&display=swap');






.locationicon {
    height: 30px;
    background-image: url(../images/images/location.png);
    background-size: cover;
    background-color:  white;
}
.locationmail {
    height: 30px;
    background-image: url(../images/images/mail.png);
    background-size: cover;
    background-color:  white;
}

.locationnumber {
    height: 30px;
    background-image: url(../images/images/call.png);
    background-size: cover;
    background-color:  white;
}

.one {
    height: 30px;
    width: 30px;
    background-image: url(../images/images/2.png);
    background-size: cover;
    background-color:  white;
}

.two {
    height: 30px;
    width: 30px;
    background-image: url(../images/images/3.png);
    background-size: cover;
    background-color:  white;
}

.three {
    height: 30px;
    width: 30px;
    background-image: url(../images/images/5.png);
    background-size: cover;
    background-color:  white;
}

.five {
    height: 30px;
    width: 30px;
    background-image: url(../images/images/1.png);
    background-size: cover;
    background-color:  white;
}

.queryhistry{
    height: 30px;
    background-image: url(../images/images/query.png);
    background-size: cover;
    background-color:  white;
}

.visa{
    height: 30px;
    width: 70px;
    background-image: url("https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/Visa_Logo.png/640px-Visa_Logo.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.ttour{
    height: 30px;
    width: 70px;
    background-image: url("https://seeklogo.com/images/B/beach-tour-logo-4505456896-seeklogo.com.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.sstudy{
    height: 30px;
    width: 70px;
    background-image: url("https://static.vecteezy.com/system/resources/thumbnails/009/383/394/small/graduation-clipart-design-illustration-free-png.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.iimmigration{
    height: 30px;
    width: 70px;
    background-image: url("https://www.eiffelimmigration.com/wp-content/uploads/2021/07/emlnnnnVn7zmhUuZ5-LCSMDj-1.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.contactbox
{   
    height: 90%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ddd;

}


.containercontact
{
    margin: 100px 100px 40px 100px;
    width: 100%;
    height: 100%;
    display: flex;
    gap: 10px;
}

.contactInfo
{
   
    
    width: 330px;
    height: 100%;
    background: #003580;
    padding: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 20px 25px rgba(0,0,0,0.15); 
    border-radius: 22px;
}

.contactInfo h2
{
    color: #fff;
    font-size:24px;
    font-weight: 500;
}

.contactInfo ul.info 
{
   position: relative;
   margin: 20px 0; 
}

.contactInfo ul.info li
{
   position: relative;
   list-style: none;
   display: flex;
   margin: 20px 0;
   cursor: pointer;
   align-items: flex-start;
}

.contactInfo ul.info li span:nth-child(1)
{
    width: 30px;
    min-width: 30px;
}

/* for gmail */
.contactInfo ul.info li span a
{
    color: #fff;
    text-decoration: none;
    width: 30px;
    min-width: 30px;
    
}

.contactInfo ul.info li span:nth-child(1) img
{
    max-width: 100%;
    filter: invert(1);
}

.contactInfo ul.info li span:nth-child(2)
{
    color: #fff;
    margin-left: 10px;
    font-weight: 300;
}

.contactInfo ul.sci
{
    position: relative;
    display: flex;
    justify-content: space-evenly;
    height: 30px;
    width: 250px;
    margin-bottom: 40px;
}

.contactInfo ul.sci li
{
   list-style: none;  
   
}

.contactInfo ul.sci li a
{
   text-decoration: none;
}

.contactInfo ul.sci li a img
{
   filter: invert(1);
}

.contactForm1
{
    
    padding: 40px;
    height: 100%;
    background: #fff; 
    box-shadow: 0 50px 50px rgba(0,0,0,0.25);
    border-radius: 22px;
    border: 1px solid black;

}

#hidden{
    display: none;
}

.contactForm2
{
    padding: 40px;
    min-height: 505px;
    height: 100%;
    width: 100%;
    background: #fff; 
    box-shadow: 0 50px 50px rgba(0,0,0,0.25);
    border-radius: 22px;

    
}

.contactForm1 h2
{
    color: #0f3959;
    font-size: 24px;
    font-weight: 500;
}

.formBox
{
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 30px;
}

.inputBox
{
    position: relative;
    margin-bottom: 35px;
}

.inputBox.w50
{
    width: 47%;
}

.inputBox.w100
{
    width: 100%;
}

.inputBox input,
.inputBox textarea, 
.inputBox select 
{
    width: 100%;
    resize: none;
    padding: 5px 0;
    font-size: 18px;
    font-weight: 300;
    color: #333;
    border: none;
    outline: none;
    border-bottom: 1px solid #777;
}

.containercontact .contactForm1 .formBox .inputBox textarea 
{
    height: 120px;
}

.containercontact .contactForm1 .formBox .inputBox span 
{
    position: absolute;
    left: 0;
    padding: 5px 0;
    pointer-events: none;
    font-size: 18px;
    font-weight: 300;
    transition: 0.3s;
}

.containercontact .contactForm1 .formBox .inputBox input:focus ~ span,
.containercontact .contactForm1 .formBox .inputBox input:valid ~ span,
.containercontact .contactForm1 .formBox .inputBox textarea:focus ~ span, 
.containercontact .contactForm1 .formBox .inputBox textarea:valid ~ span
{
    transform: translateY(-20px);
    font-size:  12px;
    font-weight: 400;
    letter-spacing: 1px;
    color: #095a55;
    font-weight: 500;
} 

/* Css for the submit button */
.containercontact .contactForm1 .formBox .inputBox input[type="submit"]         
{
    position: relative;
    cursor: pointer;
    background: #095a55;
    border-radius: 20px;
    color: #fff;
    border: none;
    max-width: 150px;
    padding: 12px;

}

.containercontact .contactForm1 .formBox .inputBox input[type="submit"]:hover{
    background: #0d9480;
}


.fthyj{
    width: 100%;
    height: 40px;
    background-color: #777;
}

.ghtuio{
    height: 35px;
    background-color: red;
}

.kudil{
    height: 100px;
    width: 100%;
    background-color: brown;
}



.bghuio{
    background-color: #0d9480;
    width: 100%;
}

.ghjg{
    position: sticky;
    display:flex;
    justify-content: flex-end;
    margin-bottom: 10px ;
}

.ghjg button {
   width: auto;
   height: 40px;
   padding: 0px 10px;
    margin-right: 5px;
    font-size: 14px;
    border-radius: 5px;
}



.accordion {
    background-color: #003580;
    color: #fff;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: 0.4s;
    display: inline;
    overflow: hidden;
  }
  

  .panel {
    padding: 0 18px;
    background-color: white;
    /* display: none; */
    overflow: hidden;
  }

  .panel span, p {
    font-size: 14px;
    
  }

  .accordion:after {
    content: '\02795'; /* Unicode character for "plus" sign (+) */
    font-size: 7px;
    color: #777;
    float: right;
    margin-left: 5px;
  }
  
  .active:after {
    content: "\2796"; /* Unicode character for "minus" sign (-) */
  }

  .ghtyu{
    display: flex;
    justify-content: space-evenly;
  }

  .bnhui{
    width: 100%;
    height: 300px;
    overflow-y: scroll;
  }

 

  @media  (min-width: 0px) and (max-width: 980px) {

    .containercontact {
        flex-direction: column-reverse;
    }

    .inputBox.w50 {
        width: 99%;
    }

    .contactInfo {
        width: 100%;
    } 
   

    
  }


  @media  (min-width: 0px) and (max-width: 450px) {
    
    .contactbox
    {   
        
        background: linear-gradient(to left, #243b55, #141e30);

    }

    .contactForm1 {
        position: relative;
        top: -51px;
    }
    

    
  }

  select:focus::-ms-value {background-color: white; color:#000;}