.listContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.listWrapper {
  width: 95%;
  display: flex;
  gap: 20px;
  justify-content: center;
}

.listSearch {
  max-width: 250px;
  /* background-color: #003580; */
  border: 1px solid #a3a0a0;
  padding: 10px;
  border-radius: 10px;
  position: sticky;
  top: 10px;
  height: 80vh;
}

.lsTitle{
  font-size: 20px;
  color: black;
  margin-bottom: 10px;
}

.lsItem{
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 10px;
}

.lsItem>label{
  font-size: 12px;
  color: black;
}

.lsItem>input{
  height: 30px;
  padding: 5px;
}
.lsItem>span{
  height: 30px;
  padding: 5px;
  background-color: white;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.lsOptions{
  padding: 10px;
}

.lsOptionItem{
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  color: #555;
  font-size: 12px;
}

.lsOptionInput{
  width: 50px;
}

.listSearch>button{
  padding: 10px;
  background-color: #003580;
  color: white;
  border: none;
  width: 100%;
  font-weight: 500;
  cursor: pointer;
}

.listResult {
  width: 80%;
  display: flex;
  
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

@media  (min-width: 0px) and (max-width: 450px) {
  .listSearch {
    display: none;
  }
}